import React, { useEffect, useState } from 'react';
import LoginForm from '../../components/login-v2/LoginForm';
import LoginBanner from '../../components/login-v2/LoginBanner';

import actions from '../../services/redux/actions';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../services/mapping';
import SocialLogin from '../../components/steps/social-login';
import Logo from '../../layouts/centered/logo';
import Link from 'next/link';
import Head from 'next/head';
import MobileBanner from '../../components/login-v2/MobileBanner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = (props) => {
  const { login, getUser, setNav, error } = props;

  const [data, setData] = useState({ email: '', password: '' });

  const { ui } = useSelector(
    (state) => ({
      ui: state.ui,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    window.location.href = 'https://beta.infloso.ai/login';
    dispatch(actions.user.clearError());
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const onDataSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await login('email', data);
      if (token) {
        const socialUser = loginUser(token['user-profile']);
        if (socialUser && socialUser.type) {
          const nav = await setNav(socialUser.type);
        }

        if (socialUser.has_domain) {
          await dispatch(actions.ui.loadTheme(socialUser.domain));
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  // console.log(ui);

  return (
    <div className="bg-white h-screen">
      <Head>
        <title>Login - {ui.name}</title>
        <meta name="description" content={ui.description} />
        <link rel="icon" href={ui.icon || ui.logo} />
      </Head>
      <div className="text-white">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ color: '#FFFFFF' }}
        />
      </div>
      <div className="flex justify-center items-center lg:h-screen flex-col bg-white">
        <div className="w-full relative bg-white md:bg-transparent py-2 lg:hidden">
          <Logo hideName={false} />
        </div>
        <div className="flex w-4/5 max-w-screen-lg mb-6 sm:shadow-custom sm:rounded-10px sm:border-1 border-gray-300">
          <LoginBanner
            img="login.png"
            title={
              <div>
                Influence everyone,
                <br />
                with<span className="text-brand-base ml-1">#{ui.name}</span>
              </div>
            }
          >
            {/*<Link href="/register">*/}
            {/*  <button*/}
            {/*    className="btn btn-default rounded-md bg-brand-base text-white"*/}
            {/*    type="button"*/}
            {/*  >*/}
            {/*    <span className="text-xs font-light">Create Account</span>*/}
            {/*  </button>*/}
            {/*</Link>*/}
          </LoginBanner>

          <div className="bg-white w-full lg:w-6/12 sm:px-10 lg:px-16 sm:py-10 lg:py-7 sm:rounded-l-10px lg:rounded-l-none sm:rounded-r-10px">
            <MobileBanner />
            <p className="font-poppins font-bold text-xl sm:text-2xl md:text-2.5xl mb-2 text-left lg:text-center">
              Log In
            </p>
            {/*<div*/}
            {/*  className={`mb-2 flex-initial bg-red-200 rounded ${*/}
            {/*    error ? 'block' : 'hidden'*/}
            {/*  }`}*/}
            {/*>*/}
            {/*  <p className="mt-2 mb-2 text-sm text-red-700 text-center py-3">*/}
            {/*    {error}*/}
            {/*  </p>*/}
            {/*</div>*/}
            <div className="-mt-6">&nbsp;</div>
            <div className="mt-6">
              <LoginForm
                error={error}
                data={data}
                setData={setData}
                onDataSubmit={onDataSubmit}
              />
            </div>
            {ui.social_login ? (
              <>
                <div className="flex mb-6 mt-6">
                  <p className="w-2/5 border-b-2 self-center"></p>
                  <p className="w-1/5 text-center text-gray-600">OR</p>
                  <p className="w-2/5 border-b-2 self-center"></p>
                </div>
                <div className="mb-6">
                  <SocialLogin />
                </div>
                <div className="text-center pt-4 pb-4">
                  <p className="text-md">
                    Not signed up yet?{' '}
                    <span className="ml-1 text-brand-base">
                      <Link href="/register">Create an account</Link>
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {ui.name === 'Infloso' && (
          <div className="ltr:ml-2 rtl:mr-2 text-md text-brand-base">
            <a target="_blank" href="/terms-of-service" className="link">
              Terms of service
            </a>
            <a target="_blank" href="privacy-policy" className="link ml-4">
              Privacy policy
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.user.error,
  };
};

const mapDispatchToProps = {
  login: actions.user.login,
  getUser: actions.user.getUser,
  setNav: actions.navigation.set,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
